$(document).ready(function () {

  var headerIsBig = true;
  var $header = $('#header');
  var $headerImages = $('#header .logo-header');
  var $navHeader = $('#navHeader');



  var footerIsBig = true;
  var $footer = $('#footer');
  var $footerImages = $('#footer .logo-footer');

  $footer.css('position', 'fixed');
  $footer.stop();
  $footerImages.stop();
  $footerImages.animate({width: '60%', margin: '0px 0'
  }, "fast").promise().then(function() {
    footerIsBig = false;
    $footer.animate({padding: '5px 0', 'border-bottom-width': '5px'}, "fast");
  });

  $(document).scroll(function() {
    var scrollTop = $(this).scrollTop();
    if (scrollTop > 100 && headerIsBig) {
      $headerImages.animate({width: '80%'}, "fast");
      $header.animate({padding: '10px 0'}, "fast");
      $navHeader.animate({padding: '0'}, "fast");
      headerIsBig = false;
    } else if (scrollTop <= 100 && !headerIsBig) {
      $headerImages.animate({width: '100%'}, "fast");
      $header.animate({padding: '20px 0'}, "fast");
      $navHeader.animate({padding: '0.5rem 1rem'}, "fast");
      headerIsBig = true;
    }

  });
});

$(document).ready(function () {
  Handlebars.registerHelper('ifCond', function (v1, operator, v2, options) {
    switch (operator) {
        case '==':
            return (v1 == v2) ? options.fn(this) : options.inverse(this);
        case '===':
            return (v1 === v2) ? options.fn(this) : options.inverse(this);
        case '<':
            return (v1 < v2) ? options.fn(this) : options.inverse(this);
        case '<=':
            return (v1 <= v2) ? options.fn(this) : options.inverse(this);
        case '>':
            return (v1 > v2) ? options.fn(this) : options.inverse(this);
        case '>=':
            return (v1 >= v2) ? options.fn(this) : options.inverse(this);
        case '&&':
            return (v1 && v2) ? options.fn(this) : options.inverse(this);
        case '||':
            return (v1 || v2) ? options.fn(this) : options.inverse(this);
        default:
            return options.inverse(this);
    }
  });
});

/********************* COOKIES *********************/

export function csrfSafeMethod(method) {
  return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
}

export function getCookie(name) {
  var cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = jQuery.trim(cookies[i]);
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export function errorMessage(msg, func) {
  $.confirm({
    title: 'Ha habido un problema...',
    content: msg,
    icon: 'fa fa-info-circle',
    theme: 'modern',
    closeIcon: true,
    animation: 'scale',
    type: 'red',
    buttons: {
      aceptar: {
        btnClass: 'btn-danger',
        action: func,
      }
    }
  });
}

export function infoMessage(msg, func) {
  $.confirm({
    title: 'Información',
    content: msg,
    icon: 'fa fa-info-circle',
    theme: 'modern',
    closeIcon: true,
    animation: 'scale',
    type: 'blue',
    buttons: {
      aceptar: {
        btnClass: 'btn-primary',
        action: func,
      }
    }
  });
}

export function infoDeleteMessage(msg, func) {
  $.confirm({
    title: 'Eliminar',
    content: msg,
    icon: 'fa fa-exclamation',
    theme: 'modern',
    closeIcon: true,
    animation: 'scale',
    type: 'red',
    buttons: {
      aceptar: {
        btnClass: 'btn-danger',
        action: func,
      },
      cancelar: {
      }
    }
  });
}

export function successMessage(tit, msg, func) {
  $.confirm({
    title: tit,
    content: msg,
    icon: 'fa fa-check',
    theme: 'modern',
    closeIcon: true,
    animation: 'scale',
    type: 'green',
    buttons: {
      aceptar: {
        btnClass: 'btn-success',
        action: func,
      }
    }
  });
}

export function successGenerate(func) {
  $.confirm({
    title: 'Información',
    content: 'Se ha generado su presentación con los últimos cambios<br>Pulse descargar para obtener el archivo.',
    icon: 'fa fa-info-circle',
    theme: 'modern',
    closeIcon: true,
    animation: 'scale',
    type: 'blue',
    buttons: {
      aceptar: {

      },
      descargar: {
        btnClass: 'btn-primary',
        action: func,
      }
    }
  });
}

export function showComentariosInput(id_comentario, bool) {
  var span_comentario = $('#comentario-' + id_comentario);
  var input = $('#edit_comentario-' + id_comentario);
  var del = $('#delete-' + id_comentario);
  var edit = $('#edit-' + id_comentario);
  var send = $('#send-' + id_comentario);
  var cancel = $('#cancel-' + id_comentario);


  if (bool) {
    input.val(span_comentario.text());
    input.fadeIn('fast');
    span_comentario.hide();
    del.hide()
    edit.hide()
    send.fadeIn('fast');
    cancel.fadeIn('fast');
  } else {
    input.hide();
    span_comentario.fadeIn('fast');
    edit.show()
    del.show();
    send.hide();
    cancel.hide();
  }
}
