export function errorMessage(title, msg) {
  $.confirm({
    title: title,
    content: msg,
    icon: 'fa fa-exclamation',
    theme: 'modern',
    closeIcon: true,
    animation: 'scale',
    type: 'red',
    buttons: {
      aceptar: {
        btnClass: 'btn-danger',
      }
    }
  });
}
