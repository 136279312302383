export function init(ctxt) {

  $("#id_zonas_anatomicas").select2({
    width: '100%',
    tags: false,
    tokenSeparators: [','],
    multiple: true,
    formatNoMatches: function() {
      return '';
    },
    "language": {
      "noResults": ""
    }
  });

  $("#id_patologias").select2({
    width: '100%',
    tags: false,
    tokenSeparators: [','],
    multiple: true,
    placeholder: ctxt.patologias,
    formatNoMatches: function() {
      return '';
    },
    "language": {
      "noResults": ""
    }
  });

  $("#id_tipos").select2({
    width: '100%',
    tags: false,
    tokenSeparators: [','],
    multiple: true,
    placeholder: ctxt.tipos,
    formatNoMatches: function() {
      return '';
    },
    "language": {
      "noResults": ""
    }
  });

  $("#id_formato").select2({
    width: '100%',
    tags: false,
    tokenSeparators: [','],
    multiple: false,
    placeholder: ctxt.formato,
    formatNoMatches: function() {
      return '';
    },
    "language": {
      "noResults": ""
    }
  });

  $("#id_tecnicas_reuma").select2({
    width: '100%',
    tags: false,
    tokenSeparators: [','],
    multiple: true,
    placeholder: ctxt.tecnicas_reuma,
    formatNoMatches: function() {
      return '';
    },
    "language": {
      "noResults": ""
    }
  });

  $('#id_caso_clinico').select2({
    width: '100%',
    tags: false,
    tokenSeparators: [','],
    multiple: false,
    allowClear: true,
    placeholder: 'Caso Clínico',
    formatNoMatches: function() {
        return '';
    },
    "language":{
      "noResults" : ""
    }
  });

  $('#send-edit').click(function () {
    $('#edit-form').submit();
  });
}
