export function postEstado(option, id, value) {
  console.log('postEstado')
  $.ajax({
    type: "POST",
    url: PUBLISH_URL,
    data: {
      'option': option,
      'item_id': id,
      'value': value
    },
    dataType: 'json',
    success: function (data) {
      switch (data.value) {
      // NO_PUBLICADO
      case 0:
        $('#item_' + data.item_id).find('.pub-dot').removeClass('green').removeClass('blue').addClass('red');
        if (option == 'caso_clinico') {
          $('#detail_case_dot').removeClass('green').removeClass('blue').addClass('red');
        } else {
          $('#detail_image_dot').removeClass('green').removeClass('blue').addClass('red');
        }
        break;
      // PUBLICADO
      case 1:
        $('#item_' + data.item_id).find('.pub-dot').removeClass('red').removeClass('blue').addClass('green');
        if (option == 'caso_clinico') {
          $('#detail_case_dot').removeClass('red').removeClass('blue').addClass('green');
        } else {
          $('#detail_image_dot').removeClass('red').removeClass('blue').addClass('green');          
        }
          break;      
      // PRESELECCIONADO
      case 2:
        $('#item_' + data.item_id).find('.pub-dot').removeClass('green').removeClass('red').addClass('blue');
        if (option == 'caso_clinico') {
          $('#detail_case_dot').removeClass('green').removeClass('red').addClass('blue');
      } else {
        $('#detail_image_dot').removeClass('green').removeClass('red').addClass('blue');        
      }
        break;
      }
    },
    error: function (jqXHR, textStatus, errorThrown) {
      console.log('Error');
      console.log(textStatus);
      console.log(errorThrown);
    },
    beforeSend: function (xhr, settings) {
      var csrftoken = $('input[name=csrfmiddlewaretoken]').val();
      if (!index.utils.csrfSafeMethod(settings.type) && !this.crossDomain) {
        xhr.setRequestHeader("X-CSRFToken", csrftoken);
      }
    }
  })
}