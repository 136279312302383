export function init(ctxt) {
    $("#id_zonas_anatomicas").select2({
        width: '100%',
        tags: false,
        tokenSeparators: [','],
        multiple: true,
        placeholder: ctxt.zonas_anatomicas,
        formatNoMatches: function () {
            return '';
        },
        "language": {
            "noResults": ""
        }
    });

    $("#id_patologias").select2({
        width: '100%',
        tags: false,
        tokenSeparators: [','],
        multiple: true,
        placeholder: ctxt.patologias,
        formatNoMatches: function () {
            return '';
        },
        "language": {
            "noResults": ""
        }
    });

    $("#id_tipos").select2({
        width: '100%',
        tags: false,
        tokenSeparators: [','],
        multiple: true,
        placeholder: ctxt.tipos,
        formatNoMatches: function () {
            return '';
        },
        "language": {
            "noResults": ""
        }
    });

    $("#id_formato").select2({
        width: '100%',
        tags: false,
        tokenSeparators: [','],
        multiple: false,
        placeholder: ctxt.formato,
        formatNoMatches: function () {
            return '';
        },
        "language": {
            "noResults": ""
        }
    });

    $("#id_tecnicas_reuma").select2({
        width: '100%',
        tags: false,
        tokenSeparators: [','],
        multiple: true,
        placeholder: ctxt.tecnicas_reuma,
        formatNoMatches: function () {
            return '';
        },
        "language": {
            "noResults": ""
        }
    });

    initFileTool();

    $('.select2-search__field').each(function () {
        $(this).attr('readonly', true);
    });

}

export function initFileTool() {
    var inputs = document.querySelectorAll('.inputfile');
    Array.prototype.forEach.call(inputs, function (input) {
    var label = input.nextElementSibling,
        labelVal = label.innerHTML;

    input.addEventListener('change', function (e) {
        var fileName = '';
        if (this.files && this.files.length > 1)
        fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
        else
        fileName = e.target.value.split('\\').pop();

        if (fileName)
        label.querySelector('span').innerHTML = fileName;
        else
        label.innerHTML = labelVal;
    });

    // Firefox bug fix
    input.addEventListener('focus', function () {
        input.classList.add('has-focus');
    });
    input.addEventListener('blur', function () {
        input.classList.remove('has-focus');
    });
    });
}
