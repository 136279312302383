import lozad from 'lozad';
var InfiniteScroll = require('infinite-scroll');
var nextURL;
var items_per_page = 6;
var total_count = 7;

const observer = lozad(); // lazy loads elements with default selector as '.lozad'

export function init(url_likes) {



  $('#navbar-button').click(function () {
    $('#collapseSearch').collapse('hide');
  });

  $('.close-search').click(function () {
    $('#collapseSearch').collapse('hide');
  });

  $('#collapse-nav-search').click(function () {
    if ($(window).width() <= 991)
      $('#navbar-button').click();
  });

  $('.blue').click(function () {
    var data;

    $('.blue').removeClass('active');
    $(this).addClass('active');
    index.gallery.filterCard(this);
    if ($(this).attr('data-mejor') == "videos") {
      $("#formato").val([4, 5]).trigger('change');
    } else if ($(this).attr('data-mejor') == "imagenes") {
      $("#formato").val([1, 2]).trigger('change');
    } else if ($(this).attr('data-mejor') == "casos") {
      $("#formato").select2('val', [3]);
    }
    $.ajax({
      type: "GET",
      url: filter_url,
      data: $('#form_filter').serialize() + "&ordering=" + $("#order").val(),
      success: function (data) {
        if (!data.error) {
          index.gallery.galleryLoadItems(data, true);
        } else {
          console.error("Error filtro: " + data.exception);
        }

      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log('Error');
        console.log(textStatus);
        console.log(errorThrown);
      },
      beforeSend: function (xhr, settings) {
        var csrftoken = $('input[name=csrfmiddlewaretoken]').val();
        if (!index.utils.csrfSafeMethod(settings.type) && !this.crossDomain) {
          xhr.setRequestHeader("X-CSRFToken", csrftoken);
        }
      }
    }).done(function () {});
  });

  $('.grid').isotope({
    itemSelector: '.item',
    animationEngine: 'jquery',
    percentPosition: true,
    masonry: {
      columnWidth: '.grid-sizer'
    },
    getSortData: {
      item_id: '.item_id parseInt',
      visualizaciones: '.views-counter parseInt'
    }
  });

  initSelect2();

  $(document).on('click', '.icon-button', function () {
    var $this = $(this);
    $this.addClass('pulse');

    $.ajax({
      type: "GET",
      url: url_likes,
      data: {
        'liked': true,
        'id': $this.attr('data-id')
      },
      dataType: 'json',
      success: function (data) {
        $('#like-counter-' + $this.attr('data-id')).html(data.likes);
        if (data.tipo == "added") {
          $this.addClass("added");
        } else {
          $this.removeClass("added");
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log('Error');
        console.log(textStatus);
        console.log(errorThrown);
      },
      beforeSend: function (xhr, settings) {
        var csrftoken = $('input[name=csrfmiddlewaretoken]').val();
        if (!index.utils.csrfSafeMethod(settings.type) && !this.crossDomain) {
          xhr.setRequestHeader("X-CSRFToken", csrftoken);
        }
      }
    }).done(function () {
      setTimeout(function () {
        $this.removeClass('pulse');
      }, 500);
    });
  });

  $(document).click(function (event) {
    // La segunda condición evita que se cierre la modal de busqueda cuando descartas un elemento de un listbox select2
    if (!$(event.target).closest('#collapseSearch').length && !$(event.target).is('[class^="select2"]')) {
      if ($('#collapseSearch').hasClass('show')) {
        $('#collapseSearch').collapse('hide');
      }
    }
  });

  $('#avance_search_a').click(function () {
    if ($(this).attr('aria-expanded') === 'true') {
      $('.border-h > svg').attr('data-icon', 'chevron-down');
    } else {
      $('.border-h > svg').attr('data-icon', 'chevron-up');
    }
  });


  $('.btn-grid').click(function () {
    $('.btn-grid').removeClass('active');
    $(this).addClass('active');
    resizeGrid();
  });

  const modal = $("#filterModal").animatedModal({
    animatedIn: 'zoomIn',
    animatedOut: 'bounceOut',
    color: '#e4e4e4',
    beforeOpen: function () {},
    afterClose: function () {}
  });

  $('#btn-search-mob').click(function () {
    $('.close-animatedModal').click();
  });

  $(window).resize(function () {
    if ($(window).width() < 1200) {
      $('.pic-height').css('height', 'auto');
      $('.image-card').css('height', 'auto');
    } else {
      $('.pic-height').css('height', '250px');
      $('.image-card').css('height', '250px');
    }
  });

  observer.observe();

  /* Recargo los valores del formulario de busqueda */
  let lsf = JSON.parse(localStorage.getItem('filter_gallery'))

  if (lsf) {
    for (var el of lsf) {
      switch (el.id) {
        case 'search':
          $('#search').val(el.value)
          break
        case 'zonas_anatomicas':
          $('#zonas_anatomicas').val(el.value).change()
          break
          case 'tipos':
          $('#tipos').val(el.value).change()
          break
          case 'tecnicas_reuma':
          $('#tecnicas_reuma').val(el.value).change()
          break
          case 'patologias':
          $('#patologias').val(el.value).change()
          break
          case 'formato':
          $('#formato').val(el.value).change()
          break
          case 'fecha_modificacion':
          $('#fecha_modificacion').val(el.value)
          break
      }
    }
  }

  //console.log(filter_url)
  nextURL = filter_url + '?' + $('#form_filter').serialize();
}

export function initSelect2() {
  $("#zonas_anatomicas").select2({
    placeholder: "Zona Anatómica",
    width: '100%',
    tags: false,
    tokenSeparators: [','],
    multiple: true,
    formatNoMatches: function() {
      return '';
    },
    "language": {
      "noResults": ""
    }
  });

  $("#tipos").select2({
    placeholder: "Tipo Imágen",
    width: '100%',
    tags: false,
    tokenSeparators: [','],
    multiple: true,
    formatNoMatches: function() {
      return '';
    },
    "language": {
      "noResults": ""
    }
  });

  $("#tecnicas_reuma").select2({
    placeholder: "Técnicas de Reumatología",
    width: '100%',
    tags: false,
    tokenSeparators: [','],
    multiple: true,
    formatNoMatches: function() {
      return '';
    },
    "language": {
      "noResults": ""
    }
  });

  $("#patologias").select2({
    placeholder: "Patología",
    width: '100%',
    tags: false,
    tokenSeparators: [','],
    multiple: true,
    formatNoMatches: function() {
      return '';
    },
    "language": {
      "noResults": ""
    }
  });

  $("#formato").select2({
    placeholder: "Formato",
    width: '100%',
    tags: false,
    tokenSeparators: [','],
    multiple: true,
    formatNoMatches: function() {
      return '';
    },
    "language": {
      "noResults": ""
    }
  });
}


export function resizeGrid() {
  var data_col = $('.btn-grid.active').data('columns');

  switch (data_col) {
    case 2:
      $('#pic-container').removeClass('container').addClass('container-fluid');
      $('.pic-height').css('height', '250px');
      $('.image-card').css('height', '250px');
      $('.description').addClass('description-xl');
      break;
    case 3:
      $('#pic-container').removeClass('container-fluid').addClass('container');
      $('.pic-height').css('height', 'auto');
      break;
    case 4:
      $('#pic-container').removeClass('container-fluid').addClass('container');
      $('.pic-height').css('height', '250px');
      $('.image-card').css('height', '250px');
      break;
    case 6:
      $('#pic-container').removeClass('container-fluid').addClass('container');
      $('.pic-height').css('height', '30em');
      $('.image-card').css('height', '30em');
      break;
  }

  if (data_col != 2) {
    $('#span-cont').addClass('pic-height');
    $('.pic-container').removeClass('6-columns');
  }

  var col = 'col-12 col-sm-6 col-lg-4 col-xl-' + data_col;
  $('.grid-sizer').removeClass().addClass('item grid-sizer ' + col);
  $('.grid').isotope();
}

export function salvaFormulario() {
  /* Solo hay que salvar los filtros cuando el usuario expresamente pulsa buscar */
  var elementos = []
  $("form :input").each(function(){
    if($(this).attr('type') != 'search') {
      //console.log($(this))
      elementos.push({
        value: $(this).val(),
        id: $(this).attr('id'),
        name: $(this).attr('name'),
        type: $(this).attr('type')
      })
    }
  })
  //console.log(elementos)
  localStorage.setItem('filter_gallery', JSON.stringify(elementos))
}

export function galleryLoadItems(data, reset) {
  $('#found').html(data.count);

  //console.log(data.next)
  nextURL = data.next;
  total_count = data.count;

  var html = template(data);
  if (reset) {
    $('.grid').html(html);
    resizeGrid();
    $('.grid').isotope('reloadItems').isotope();
  } else {
    var $items = $(html);
    $('.grid').append($items);
    resizeGrid();
    $('.grid').isotope('appended', $items);
  }
  observer.observe();
}


export function filterGallery() {
  //Reseteamos la paginacion
  $("#load-finish").hide();
  infScroll.pageIndex = 1;
  infScroll.loadCount = 1;
  infScroll.canLoad = true;
  infScroll.isScrollWatching = true;
  infScroll.bindScrollWatchEvents(true);
  $.ajax({
    type: "GET",
    url: filter_url,
    data: $('#form_filter').serialize(),
    success: function (data) {
      if (!data.error) {
        index.gallery.galleryLoadItems(data, true);
      } else {
        console.error("Error filtro: " + data.exception);
      }
    },
    beforeSend: function (xhr, settings) {
      var csrftoken = $('input[name=csrfmiddlewaretoken]').val();
      if (!index.utils.csrfSafeMethod(settings.type) && !this.crossDomain) {
        xhr.setRequestHeader("X-CSRFToken", csrftoken);
      }
    }
  })
}

export function multipleItems() {
  $('.multiple-items').slick({
    dots: false,
    infinite: true,
    arrows: false,
    speed: 30,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [{
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });


  infScroll = new InfiniteScroll('.grid', {
    path: function () {
      //console.log((this.loadCount*items_per_page)+ '------'+(total_count));
      // PUNTO DE ENTRADA EQUIVALENTE A loadGallery
      //console.log(this)
      if((this.loadCount*items_per_page)<(total_count)) {
        //console.log('Caso A')
        return nextURL
      } else {
        //console.log('CASO B')
      }

    },
    responseType: 'text',
    history: false,
    status: '.page-load-status'
  });

  infScroll.on('load', function (response, opts) {
    if (response == "") {
      infScroll.isScrollWatching = false;
    } else {
      var data = JSON.parse(response);

      if (infScroll.loadCount == 1) {
        index.gallery.galleryLoadItems(data, true);
      } else {
        index.gallery.galleryLoadItems(data, false);
      }
    }
  });

  infScroll.on('last', function (response, opts) {
    $("#load-finish").show();
  });

  // load initial page
  infScroll.loadNextPage();
}


export function filterCard(selector) {
  var query = $(selector).find('.order-icon');

  if (!query.hasClass('hidden')) {
    if (query.find('svg').attr('data-icon') === 'chevron-down') {
      query.find('svg').attr('data-icon', 'chevron-up');
      $("#order").val("likes,id");
    } else {
      query.find('svg').attr('data-icon', 'chevron-down');
      $("#order").val("-likes,id");
    }
  } else {
    $("#order").val("-likes,id");
  }

  $('.blue .order-icon').addClass('hidden');
  query.removeClass('hidden');

}

export function limpiarFiltros() {
  $('.blue').removeClass('active');
  $('.order-icon').addClass('hidden');

  $('.select2').val([]).change();
  $('#form_filter')[0].reset();
  salvaFormulario()


  filterGallery();
}
