require('babel-polyfill');
require('bootstrap');

import fontawesome from '@fortawesome/fontawesome'
import solid from '@fortawesome/fontawesome-free-solid'
import style from "../sass/main.scss";

import 'jquery-confirm/dist/jquery-confirm.min.css';
import 'imports-loader?exports=>undefined,require=>false,this=>window!caman';
import "jquery-confirm";
import "select2";
import 'select2/dist/css/select2.css';
import 'slick-carousel';
import 'slick-carousel/slick/slick-theme.scss';
import 'slick-carousel/slick/slick.scss';
import 'animate.css/animate.css';
import 'animatedmodal/animatedModal.js';

import { getCookie } from "./django_ajax.js";

import * as edit_image_tool from './edit_image_tool.js';
import * as edit_image from './edit_image.js';
import * as edit_video from './edit_video.js';
import * as new_image from './new_image.js';
import * as new_video from './new_video.js';
import * as detail_image from './detail_image.js';
import * as detail_video from './detail_video.js';
import * as utils from './utils.js';
import * as gallery from './gallery.js';
import * as fondo from './fondo.js'
import * as casos_clinicos from './casos_clinicos.js';
import * as alertas from "./alertas.js";
import * as estado_api_client from './estado_api_client.js'
import * as estadisticas from './estadisticas.js'

export { edit_image_tool }
export { edit_image }
export { edit_video }
export { detail_image }
export { detail_video }
export { new_image }
export { new_video }
export { utils }
export { gallery }
export { fondo }
export { casos_clinicos }
export { alertas }
export { estado_api_client }
export { estadisticas }

fontawesome.library.add(solid.faUser)
window.jQuery = $;
window.$ = $;

import jQueryBridget from 'jquery-bridget';
import Isotope from 'isotope-layout/dist/isotope.pkgd.js';
jQueryBridget('isotope', Isotope, $);

import Packery from 'packery/dist/packery.pkgd.js';
jQueryBridget('packery', Packery, $);

require('jquery-ui-bundle');

import { CookieConsent } from 'cookieconsent';
import 'cookieconsent/build/cookieconsent.min.css';
