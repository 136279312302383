import * as utils from './utils.js';
import Vue from 'vue';
import VueResource from 'vue-resource';
import * as alertas from "./alertas.js";

var pics = null;

export function init(attr) {
  var array = []

  Vue.http.headers.common['X-CSRFToken'] = $('input[name=csrfmiddlewaretoken]').val();
  pics = new Vue({
    el: '#vue-box',
    delimiters: ['${', '}'],
    data: {
      grid: $('#gridB'),
      lists_name: [],
      lists_onload: [],
      cards: [],
      list_cards: [],
      lista_object: null,
      list_id: null,
      opt: 'ALL_CARDS_FAV'
    },
    mounted: function () {
      this.getAllLists();
      this.getMyCards(this.opt);
      this.$nextTick(function () {
        window.addEventListener('resize', this.reloadDraggable);
      });
    },
    methods: {
      getEstadoClass: function (estado) {
        // PUBLICADO
        if (estado == 1) {
          return 'pub-dot green' 
        }
        else {
          // PRESELECCIONADO
          if (estado == 2) {
            return 'pub-dot blue'
          // NO PUBLICADO
          } else if (estado == 0) {
            return 'pub-dot red'
          }
        }
      },
      reloadDraggable: function event() {
        this.$nextTick(function () {
          initShapeshift($("#gridA"));
          if(this.list_id != null)
            initShapeshift($("#gridB"));
        })
      },
      createList: function () {
        var value = $('#list-value').val();
        this.$http.post('/core/fondo/', {
            lista: value,
            option: 'CREATE_LIST',
          })
          .then((response) => {
            this.selectList(response.data.id);
            this.getAllLists();

            $('#mylist').removeClass('hide');
            $('#newListModal').modal('toggle');
            $('#list-value').val(null);
          })
          .catch((err) => {
            this.loading = false;
            console.log('Error');
            console.log(err);
          });
      },
      getMyCards: function (opt) {
        this.cards = [];
        this.$http.get('/core/fondo/?option=' + opt)
          .then((response) => {
            this.cards = response.data.vi_array;
            console.log(this.cards);
            if (this.list_cards.length > 0) {
              for (var i = 0; i < (this.list_cards).length; i++) {
                for (var j = 0; j < (this.cards).length; j++) {
                  if (this.cards[j].id == this.list_cards[i].id) {
                    this.cards.splice(j, 1);
                  }
                }
              }
            }

            this.$nextTick(function () {
              this.reloadDraggable();
            })

            this.opt = opt;
          })
          .catch((err) => {
            this.loading = false;
            console.log('Error');
            console.log(err);
          });
      },
      getAllLists: function () {
        this.$http.get('/core/fondo/?option=ALL_LISTS')
          .then((response) => {
            this.lists_name = response.data.lista_array;
            this.lists_onload = response.data.lista_array;

            if (this.lists_name.length == 0) {
              $('#mylist').addClass('hide');
            }

          })
          .catch((err) => {
            this.loading = false;
            console.log('Error');
            console.log(err);
          });
      },
      selectList: function (idLista) {
        $("#div_selecciona_lista").remove();
        $('#gen-ppt').removeClass('hide').attr('data-change', false);
        $('#save-btn').removeClass('hide');
        this.list_cards = [];
        this.grid.attr('data-new', false);

        this.$http.get('/core/fondo/?lista_id=' + idLista + '&option=ALL_CARDS')
          .then((response) => {
            console.log(response);

            var obj = JSON.parse(response.data.lista_array)
            this.list_cards = response.data.vi_array
            this.lista_object = obj[0]
            this.list_id = obj[0].pk

            this.getMyCards(this.opt);

            if (this.list_cards.length == 0) {
              $('#gen-ppt').addClass('hide');
            }

            $('#vue-box h3').html(obj[0].fields.lista);

            this.$nextTick(function () {
              this.reloadDraggable();
            })
          })
          .catch((err) => {
            this.loading = false;
            console.log('Error');
            console.log(err);
          });
      },
      changeName: function () {
        this.$http.post('/core/fondo/', {
            title: $('#edit-title').val(),
            option: 'CHANGE_TITLE',
            id_lista: $('#gridB').attr('data-id-lista')
          })
          .then((response) => {
            var obj = JSON.parse(response.data.lista_array);
            this.lista_object = obj[0];
            this.getAllLists();
            $('#input-lista-tit').addClass('hide');
            $('#lista-current-tit').removeClass('hide');
          })
          .catch((err) => {
            this.loading = false;
            console.log('Error');
            console.log(err);
          });
      },
      cancelEdit: function (text) {
        $('#edit-title').val($('#lista-current-tit').text())
        $('#input-lista-tit').addClass('hide');
        $('#lista-current-tit').removeClass('hide');
      },
      deleteList: function (id) {
        var $this = this;
        utils.infoDeleteMessage('¿Está seguro que desea borrar esta presentación?', function () {
          $this.$http.delete('/core/fondo/?id_lista=' + id)
            .then((response) => {
              console.log(response.data);
              $('#lista-current-tit').html('Mi Fondo');
              $('a[data-lista-id="' + id + '"]').remove();
              $('#gridB').removeAttr("data-id-lista");

              $('#save-btn').addClass('hide');
              $('#gen-ppt').addClass('hide');

              if (response.data.lista_array.length == 0) {
                $('#mylist').addClass('hide');
              }

              $this.list_cards = [];
              $this.getAllLists();
              $this.getMyCards($this.opt);
            })
            .catch((err) => {
              $this.loading = false;
              console.log('Error');
              console.log(err);
            });
        });
      },
      resetVue: function () {
        this.lists_name = []
        this.lists_onload = []
        this.cards = []
        this.list_cards = []
        this.lista_object = null
        this.list_id = null
        this.opt = 'ALL_CARDS_FAV'

        this.getAllLists();
        this.getMyCards('ALL_CARDS_FAV');
      },
      save: function () {
        var array = []

        $("#gridB .item").each(function (i, itemElem) {
          array.push($(this).attr('id'))
        });

        if(array.length < 1) {
          alertas.errorMessage('No es posible guardar una presentación sin imágenes.', 'Por favor seleccione alguna imagen del panel de su izquierda');
          return
        }

        var change = $('#gen-ppt').attr('data-change');

        var formData = new FormData();
        formData.append('option', 'SAVE_ITEMS')
        formData.append('lista_id', $('#gridB').attr('data-id-lista'))
        formData.append('array', array)
        formData.append('change', change)
        formData.append('csrfmiddlewaretoken', $('input[name=csrfmiddlewaretoken]').val())

        this.$http.post(attr.url_ajax, formData)
        .then(function(response){
          this.selectList(this.list_id);
           if (response.error) {
             alertas.errorMessage('Ha habido un error, por favor vuelva a intentarlo', 'Si el problema persiste contacte con el administrador.');
             console.error("Error tipo: " + response.exception);
           } else if (change == 'true') {
             utils.successGenerate(function () {
               window.open('/core/ppt/' + response.lista_id, '_blank');
             });
           } else {
             utils.successMessage('Presentación guardada', 'La presentación ha sido guardada con éxito', null)
             $('#gen-ppt').removeClass('hide');
             $('#del-ppt').removeClass('hide');
           }
         }).catch((err) => {
           console.log('Error');
           console.log(err);
           alertas.errorMessage('Ha habido un error, por favor vuelva a intentarlo.', 'Si el problema persiste contacte con el administrador.');
         });


      },
      save_favorito: function (id) {
        var $this = $('.icon-button[data-id="' + id + '"]')
        $this.addClass('pulse');
        this.$http.get(attr.url_likes+'?liked=true&id='+id)
        .then(function(response) {
            console.log('Favorito enviado correctamente')
            console.log(response)
            $('#like-counter-' + id).html(response.body.likes);
            if (response.body.tipo == "added") {
              $this.addClass("added");
            } else {
              $this.removeClass("added");
            }
        })
        .catch(err => {
            console.log("Error creando favorita");
            console.log(err);
        })
      }
    }
  });



  $('#listModal').click(function () {
    $('#newListModal').modal('show');
  });

  $(document).on('click', '#lista-current-tit', function () {
    if (!$(this).hasClass('hide')) {
      $('#input-lista-tit').removeClass('hide');
      $(this).addClass('hide')
    } else {
      $('#input-lista-tit').addClass('hide');
      $(this).removeClass('hide')
    }
  });

  $('#gen-ppt').click(function () {
    var id = $(this).attr('data-id');
    var change = $(this).attr('data-change');

    if (change == 'true')
      $('#save-btn').click();
    else
      window.open('/core/ppt/' + id, '_blank');
  });

  $('.grid-nav').click(function () {
    $('.grid-nav').removeClass('active');
    $(this).addClass('active');
  });

  $('#indice-btn').click(function () {
    window.location.href = attr.home + '?index=true';
  });

  $('#gridA, #gridB').on("ss-arranged", function(e) {
    setTimeout(function(){
      if($("#gridB").find( ".item" ).length>$("#gridA").find( ".item" ).length)
        $("#gridA").css({'height':$("#gridB").css('height')})
      else
        $("#gridB").css({'height':$("#gridA").css('height')})
    }, 500);
  });
}


function initShapeshift(grid) {

  if (grid.find('.item').length == 0)
    var size_sampple = $('<div>', {
      html: 'nbsp;',
      class: 'grid-sizer col-12 col-sm-6 col-lg-3 item size_example'
    }).appendTo(grid);

  //Deshabilitamos el autoheight al grid mas bajo para ajustarlo con on("ss-arranged") en el init del script
  if(grid.attr("id")=="gridA")
  {
    if($("#gridB").find( ".item" ).length>$("#gridA").find( ".item" ).length)
      var autoHeight = false;
    else
      var autoHeight = true;
  }
  else
  {
    if($("#gridA").find( ".item" ).length>$("#gridB").find( ".item" ).length)
      var autoHeight = false;
    else
      var autoHeight = true;
  }

  grid.shapeshift({
    selector: "div",
    enableDrag: true,
    enableCrossDrop: true,
    enableResize: true,
    enableTrash: false,
    align: "left",
    colWidth: null,
    columns: null,
    minColumns: 1,
    autoHeight: autoHeight,
    maxHeight: null,
    minHeight: null,
    gutterX: 0,
    gutterY: -5,
    paddingX: 0,
    paddingY: 0,
    animated: true,
    animateOnInit: false,
    animationSpeed: 225,
    animationThreshold: 100,
    dragClone: false,
    deleteClone: true,
    dragRate: 100,
    dragWhitelist: "*",
    crossDropWhitelist: "*",
    cutoffStart: null,
    cutoffEnd: null,
    handle: false,
    cloneClass: "ss-cloned-child",
    activeClass: "ss-active-child",
    draggedClass: "ss-dragged-child",
    placeholderClass: "ss-placeholder-child",
    originalContainerClass: "ss-original-container",
    currentContainerClass: "ss-current-container",
    previousContainerClass: "ss-previous-container"
  });


  grid.find('.size_example').remove();

  setTimeout(function(){ $("#gridB").css({'height':$("#gridA").css('height')}) }, 1000);


}
