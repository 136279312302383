import Vue from 'vue';
import VueResource from 'vue-resource';
import * as utils from './utils.js';

Vue.use(VueResource);

export function init(json) {
    $('#plusButton').click(function () {
        $('#addNewCC').modal('toggle');
    });

    $('.clinical-case').click(function () {
        window.location = $(this).data('url');
    });

    $('.thumb-container').slick({
        dots: true,
        infinite: false,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
}

export function newCase(attr) {
    $('.thumb-div').click(function () {
        var $this = $(this);
        if ($this.hasClass('active')) {
            $this.removeClass('active');
        } else {
            $this.addClass('active');
        }
    });

    $('#send-cc').click(function () {
        addIds();
        $('#form-cc').submit();
    });

    function addIds() {
        var $active = $('.active');
        var total = $active.length;
        var data = '';

        $('#id_vi_ids').val(null);

        $active.each(function (index) {
            if (index === total - 1) {
                data += $(this).data('pic-id');
            } else {
                data += $(this).data('pic-id') + ',';
            }
            $('#id_vi_ids').val(data);
        });
    }

    $('.edit-cc').click(function () {
        var data_id = $(this).attr('data-id');
    });
    if (attr.cc_vi != null) {
        for (var i=0; i < attr.cc_vi.length; i++) {
            $('div[data-pic-id="'+ attr.cc_vi[i] +'"]').addClass('active');
        }
    }
}
