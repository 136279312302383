import Vue from 'vue';
import VueResource from 'vue-resource';
import * as utils from './utils.js';
import moment from 'moment'

Vue.use(VueResource);

if (!String.prototype.padStart) {
  String.prototype.padStart = function padStart(targetLength,padString) {
      targetLength = targetLength>>0; //truncate if number or convert non-number to 0;
      padString = String((typeof padString !== 'undefined' ? padString : ' '));
      if (this.length > targetLength) {
          return String(this);
      }
      else {
          targetLength = targetLength-this.length;
          if (targetLength > padString.length) {
              padString += padString.repeat(targetLength/padString.length); //append to original to ensure we are longer than needed
          }
          return padString.slice(0,targetLength) + String(this);
      }
  };
}

var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

today = yyyy + '-' + mm + '-' + dd;

function isValidDate(d) {
  var m = moment(d, 'YYYY-MM-DD')

  return m.isValid()
}

export function init(ctxt) {
    var app = new Vue({
      el: '#estadisticas',
      delimiters: ['${', '}'],
      data: {
        fecha: today,
        errores: false,
        message: null,
        imagenes_publicadas: 0,
        imagenes_no_publicadas: 0,
        imagenes_preseleccionadas: 0,
        videos_publicados: 0,
        videos_no_publicados: 0,
        videos_preseleccionados: 0,
        casos_clinicos_publicados: 0,
        casos_clinicos_no_publicados: 0,
        casos_clinicos_preseleccionados: 0
      },      
      mounted: function () {
        this.getEstadisticas();
      },
      watch: {
        fecha(val) {
          this.errores = !isValidDate(val)
          console.log(isValidDate(val))
          console.log(val)
        }
      },
      methods: {
        getExcel: function() {
          window.open('/core/xlsx/' + this.fecha, '_blank');
        },
        getEstadisticas: function (id) {
          //console.log(id);
          this.loading = true;
          this.$http.get('/core/api/estadisticas/' + this.fecha)
            .then((response) => {
                console.log(response.data)
                this.imagenes_publicadas = response.data.imagenes_publicadas
                this.imagenes_no_publicadas = response.data.imagenes_no_publicadas
                this.imagenes_preseleccionadas = response.data.imagenes_preseleccionadas
                this.videos_no_publicados = response.data.videos_no_publicados
                this.videos_preseleccionados = response.data.videos_preseleccionados
                this.videos_publicados = response.data.videos_publicados
                this.casos_clinicos_no_publicados = response.data.casos_clinicos_no_publicados
                this.casos_clinicos_preseleccionados = response.data.casos_clinicos_preseleccionados
                this.casos_clinicos_publicados = response.data.casos_clinicos_publicados
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
            })
        },
      }
    });
  }
  