var ImageEditor = require('tui-image-editor');
import 'tui-image-editor/dist/tui-image-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';

var instance;

export function init(ctxt) {
  if($('#id_image_base64', window.parent.document).val() == "")
  {
    instance = new ImageEditor(document.querySelector('#tui-image-editor'), {
         includeUI: {
             loadImage: {
               path: ctxt.videoimagen,
               name: 'Imagen'
             },
             theme: blackTheme,
             initMenu: null,
             menu: ['crop', 'flip', 'rotate', 'draw', 'shape', 'icon', 'text', 'mask', 'filter'],
             menuBarPosition: 'bottom'
         },
        cssMaxWidth: 700,
        cssMaxHeight: 1500,
        selectionStyle: {
            cornerSize: 20,
            rotatingPointOffset: 70
        }
    });
  }


  $('#save-image').click(function(){
    setImageViewer();
  });

  $('#cancel-image').click(function(){

    $('#modal-edit', window.parent.document).click();

  });

  function setImageViewer()
  {
    var image_base64 = instance.toDataURL();
    $('#id_image_base64', window.parent.document).val(image_base64);
    $('#img_detail', window.parent.document).attr('src', image_base64);
    $('.viewer-canvas img', window.parent.document).attr('src', image_base64);
    $('#modal-edit', window.parent.document).click();
  }

}
