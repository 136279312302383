import * as alertas from "./alertas.js";
import 'viewerjs/dist/viewer.css';
import Viewer from 'viewerjs/dist/viewer.js';
import "select2";
import Vue from 'vue';
import VueResource from 'vue-resource';
import * as utils from './utils.js';

Vue.use(VueResource);

export function init(ctxt) {
  var image = document.getElementById('img_detail');
  var viewer = new Viewer(image, {
    inline: true,
    navbar: false,
    fullscreen: false,
    url(image) {
      return ctxt.img_url;
    },
    title: false,
    viewed: function () {
      viewer.zoomTo(0.5);
    },
    toolbar: {
      zoomIn: 4,
      zoomOut: 4,
      oneToOne: 0,
      reset: 4,
      prev: 0,
      play: 0,
      next: 0,
      rotateLeft: 4,
      rotateRight: 4,
      flipHorizontal: 4,
      flipVertical: 4,
    }
  });
}

export function asyncComments(ctxt) {
  Vue.http.headers.common['X-CSRFToken'] =
    document.querySelector('[name="csrfmiddlewaretoken"]').value;

  var app2 = new Vue({
    el: '#starting',
    delimiters: ['${', '}'],
    data: {
      id: null,
      tipo: null,
      url: null,
      comentarios: [],
      loading: false,
      message: null,
      newComentario: {
        'contenido': null,
        'usuario': ctxt.userid
      },
      editComentario: {
        'contenido': null,
        'id_comentario': null,
        'option': 'EDIT'
      }
    },
    mounted: function () {
      if (ctxt.cc_id) {
        this.tipo = 'CC'
        this.id = ctxt.cc_id
      } else {
        this.tipo = 'VI'
        this.id = ctxt.imageid
      }

      this.url = '/core/api/api_comentarios/' + this.id + '/' + this.tipo + '/'
      this.getComentarios();
    },
    methods: {
      getComentarios: function () {
        this.loading = true;
        this.$http.get(this.url)
          .then((response) => {
            console.log(response)
            this.comentarios = response.data;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          })
      },
      addComentario: function () {
        this.loading = true;
        this.$http.post(this.url, this.newComentario)
          .then((response) => {
            this.loading = false;
            this.getComentarios();
            $('#addComentarioModal').modal('hide');
            $('#contenido').val(null);
          })
          .catch((err) => {
            this.loading = false;
            $('#addComentarioModal').modal('hide');
            console.log(err);
          })
      },
      hideInputComentario(id) {
        utils.showComentariosInput(id, false);
      },
      showInputComentario: function (id) {
        utils.showComentariosInput(id, true);
      },
      sendEditComentario: function (id) {
        this.loading = true;
        this.editComentario.contenido = $('#edit_comentario-' + id).val();
        this.editComentario.id_comentario = id;

        this.$http.post(this.url, this.editComentario)
          .then((response) => {
            this.loading = false;
            utils.showComentariosInput(id, false);
            this.getComentarios();
          })
          .catch((err) => {
            this.loading = false;
            $('#addComentarioModal').modal('hide');
            console.log(err);
          })
      },
      deleteComentario: function (id) {
        var $this = this;
        utils.infoDeleteMessage('¿Está seguro que desea borrar este comentario?', function () {
          $this.loading = true;

          $this.$http.delete('/core/api/api_comentarios/' + id + '/' + this.tipo + '/')
            .then((response) => {
              $this.getComentarios();
              $this.loading = false;
            })
            .catch((err) => {
              $this.loading = false;
              console.log(err);
            })
        });
      },
    }
  });
}
