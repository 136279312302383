import * as alertas from "./alertas.js";

export function init(ctxt) {
  $("#id_zonas_anatomicas").select2({
    width: '100%',
    tags:false,
    tokenSeparators: [','],
    multiple: true,
    placeholder: ctxt.zonas_anatomicas,
    formatNoMatches: function() {
        return '';
    },
    "language":{
      "noResults" : ""
    }
  });

  $("#id_patologias").select2({
    width: '100%',
    tags: false,
    tokenSeparators: [','],
    multiple: true,
    placeholder: ctxt.patologias,
    formatNoMatches: function() {
        return '';
    },
    "language":{
      "noResults" : ""
    }
  });

  $("#id_tipos").select2({
    width: '100%',
    tags: false,
    tokenSeparators: [','],
    multiple: true,
    placeholder: ctxt.tipos,
    formatNoMatches: function() {
        return '';
    },
    "language":{
      "noResults" : ""
    }
  });

  $("#id_formato").select2({
    width: '100%',
    tags: false,
    tokenSeparators: [','],
    multiple: false,
    placeholder: ctxt.formato,
    formatNoMatches: function() {
        return '';
    },
    "language":{
      "noResults" : ""
    }
  });

  $("#id_tecnicas_reuma").select2({
    width: '100%',
    tags: false,
    tokenSeparators: [','],
    multiple: true,
    placeholder: ctxt.tecnicas_reuma,
    formatNoMatches: function() {
        return '';
    },
    "language":{
      "noResults" : ""
    }
  });

  $('#id_caso_clinico').select2({
    width: '100%',
    tags: false,
    tokenSeparators: [','],
    multiple: false,
    allowClear: true,
    placeholder: 'Caso Clínico',
    formatNoMatches: function() {
        return '';
    },
    "language":{
      "noResults" : ""
    }
  });

    const target = document.getElementById('upload-btn-wrapper');
    const output = document.getElementById('id_fichero');
    const preview = document.getElementById('previewImg');

    $("#uploadform").submit(function(event) {
      event.preventDefault();

      $.ajax({
        data: new FormData($("#uploadform")[0]),
        type: $(this).attr('method'),
        url: $(this).attr('action'),
        processData: false,
        contentType: false,
        success: function(response) {
             console.log(response);
             if (response.status === "ok") {
               window.location.replace(response.url);
             } else {

               var texto = response.errores.map(function(elem){
                 return elem.texto;
               }).join("<br>");

               alertas.errorMessage('Error', texto);
             }
        },
        error: function (request, status, error) {
             console.log(request.responseText);
        }
      });
    });

    target.addEventListener('drop', (e) => {
      e.stopPropagation();
      e.preventDefault();

      uploadFiles(e.dataTransfer.files);
    });

    target.addEventListener('dragover', (e) => {
      e.stopPropagation();
      e.preventDefault();

      e.dataTransfer.dropEffect = 'copy';
    });

    output.addEventListener('change', (e) => {
      $('#previewImg').removeClass('border');

      var reader = new FileReader();
      reader.onload = function (e) {
          preview.src = e.target.result;
      };

      reader.readAsDataURL(e.target.files[0]);
    });

    function uploadFiles(fileList) {
      output.files = fileList;
      preview.src = URL.createObjectURL(fileList[0]);
    }

    $('.select2-search__field').each(function () {
      if ($(this).attr('id') === 'id_caso_clinico')
        $(this).attr('readonly', true);
    });
}
